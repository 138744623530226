import { RalphCopy } from '../../data'
import './Header.styles.scss'
import { useLocation, useNavigate } from 'react-router-dom'

function Header() {
  const { header } = RalphCopy

  const location = useLocation()
  const navigate = useNavigate()

  const openLink = (_link) => {
    window.open(_link, '_blank')
  }

  const openSubscribe = () => {
    document.getElementById('subscribe-overlay').classList.add('active')
    document.body.classList.add('noscroll')
  }

  return (
    <header>
      <div className="top">
        <img src={require(`../../assets/imgs/${header.tagline}`)} alt="Ralph" />
      </div>
      <div className={`bottom container ${location.pathname === '/' ? '' : 'small'}`}>
        <button
          className="r-button flash"
          onClick={() => {
            openLink(header.buynow.url)
          }}
        >
          {header.buynow.text}
        </button>
        <button
          className="logo-button"
          onClick={() => {
            navigate('/')
          }}
        >
          <h1>
            <img src={require(`../../assets/imgs/ralph.svg`).default} alt="Ralph" />
          </h1>
        </button>

        <div className="social-buttons">
          <button
            className="r-button"
            onClick={() => {
              openLink(header.instagram.url)
            }}
          >
            {header.instagram.text}
          </button>
          <button
            className="r-button"
            onClick={() => {
              openSubscribe()
            }}
          >
            {header.subscribe.text}
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header
