import { RalphCopy } from '../../data'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import './Homepage.styles.scss'

function HomePage() {
  const navigate = useNavigate()

  const { issues } = RalphCopy

  console.log(issues)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const openLink = (_link) => {
    window.open(_link, '_blank')
  }

  const changeRoute = (_link) => {
    navigate(_link)
  }

  return (
    <div className="issues-container">
      {issues.map((issue, index) => (
        <div className="container home page-container" key={`home-issue-${index}`}>
          <div className="flex-half left">
            <div className="issue-container">
              <img
                src={require(`../../assets/imgs/${issue.home.issue.img}`)}
                alt={issue.home.issue.alt}
              />
            </div>
          </div>
          <div className="flex-half right">
            <div className="info-box">
              <h2> {issue.home.title}</h2>
              <p>{issue.home.intro}</p>
              <button
                className="r-button small"
                onClick={() => {
                  openLink(issue.home.buynow.url)
                }}
              >
                {issue.home.buynow.text}
              </button>
              <button
                className="r-button small"
                onClick={() => {
                  changeRoute(`${issue.home.support.url}`)
                }}
              >
                {issue.home.support.text}
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default HomePage
