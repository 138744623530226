export const Issue1 = {
  home: {
    title: 'ISSUE 1 - SUMMER 2024',
    intro:
      'Ralph Issue 1, where we ask Flavor Flav what the weather’s like where he is, get the showrunner of The Simpsons to tell us what fast food items Bart, Homer, Lisa, Ned Flanders et al would be, find out why Japan’s music has been influencing the West for decades, get the inside line on chef Olivia Tiedemann’s best New York pizza spots, have pints with buzzy band Fat Dog, do a fashion shoot at Mumbai Comic Con and tons more.',
    buynow: {
      text: 'Ralph in your hands',
      url: 'https://www.mmslondon.co.uk/shop/p/ralphmagazine'
    },
    support: {
      text: 'Ralph on your screen',
      url: '/masthead/#issue1'
    },
    issue: {
      img: 'issue_1_highres.png',
      alt: 'Ralph Issue 1'
    }
  },
  masthead: {
    id: 'issue1',
    contributors: {
      title: 'Contributors',
      list: [
        { name: 'Matt Adam', target: '_blank', url: 'https://www.instagram.com/mattadam___/' },
        {
          name: 'Kareem Black',
          target: '_blank',
          url: 'https://www.instagram.com/kareemblack/?hl=en-gb'
        },
        { name: 'Bimini', target: '_blank', url: 'https://www.instagram.com/biminibabes/?hl=en' },
        {
          name: 'Patrick Clarke',
          target: '_blank',
          url: 'https://www.instagram.com/paddy__clarke__'
        },
        { name: 'Tej Datar', target: '_blank', url: 'https://www.instagram.com/ttejdatar/?hl=en' },
        {
          name: 'Jeff Definitely',
          target: '_blank',
          url: 'https://www.instagram.com/jeff.definitely/'
        },
        { name: 'Chloe Laight', target: '_self', url: '/masthead' },
        {
          name: 'Jimmy McIntosh',
          target: '_blank',
          url: 'https://www.instagram.com/atoosamoinzadeh/?hl=en'
        },
        {
          name: 'Atoosa Moinzadeh',
          target: '_blank',
          url: 'https://www.instagram.com/atoosamoinzadeh/ '
        },
        {
          name: 'Bill Oakley',
          target: '_blank',
          url: 'https://www.instagram.com/thatbilloakley/?hl=en'
        },
        {
          name: 'Aiyush Pachnanda',
          target: '_blank',
          url: 'https://www.instagram.com/_yushy/?hl=en'
        },
        {
          name: 'Kevin EG Perry',
          target: '_blank',
          url: 'https://www.instagram.com/kevinegperry/?hl=en'
        },
        { name: 'Popcorn Pirate', target: '_self', url: '' },
        {
          name: 'Andrew Rae',
          target: '_blank',
          url: 'https://www.instagram.com/andrewjrae/?hl=en'
        },
        {
          name: 'Hannah Slaney',
          target: '_blank',
          url: 'https://www.instagram.com/hannahslaneyphotography/'
        },
        {
          name: 'Stephanie Sian Smith',
          target: '_blank',
          url: 'https://www.instagram.com/stephaniesiansmith/'
        },
        { name: 'Dan Wilton', target: '_blank', url: '/masthead' }
      ]
    },
    features: {
      title: 'Links',
      list: [
        {
          name: 'Liner Notes',
          target: '_blank',
          url: 'https://www.instagram.com/linernotesvinylclub/?hl=en-gb'
        },
        {
          name: 'Olivia Tiedemann',
          target: '_blank',
          url: 'https://www.instagram.com/oliviatied/'
        },
        {
          name: 'Mumbai Comic Con',
          url: 'https://www.instagram.com/comicconindia?igsh=MTQ0dXBsNjh0NmNhOA%3D%3D'
        },
        {
          name: 'Ahilya Bamroo',
          target: '_blank',
          url: 'https://www.instagram.com/ahillyeah/?hl=en'
        },
        {
          name: 'Mia Khalifa',
          target: '_blank',
          url: 'https://www.instagram.com/miakhalifa/?hl=en'
        },
        {
          name: 'Flavor Flav ',
          target: '_blank',
          url: 'https://www.instagram.com/flavorflavofficial/?hl=en'
        },
        {
          name: 'Fat Dog',
          target: '_blank',
          url: 'https://www.instagram.com/fatdogfatdogfatdog_/?hl=en'
        },
        {
          name: 'Jeff Innocent',
          target: '_blank',
          url: 'https://www.instagram.com/jeffinnocentofficial/'
        },
        {
          name: 'LA Takeovers Film',
          target: '_blank',
          url: 'https://www.youtube.com/watch?v=76nryuwqjxI'
        }
      ]
    },
    promotions: [
      {
        title: 'Order your Ralph socks',
        img: 'promotion_socks.png',
        url: 'https://forms.gle/Gz56PRdARYF5L5hc8'
      },
      {
        title: 'Bimini’s Bangers Spotify Playlist',
        img: 'promotion_spotify.png',
        url: 'https://open.spotify.com/playlist/3yzmecqvv5o19LgfgN5d2u?si=15ed6f8467454147'
      }
    ]
  }
}
