import { Routes, Route } from 'react-router-dom'
import HomePage from './components/HomePage/HomePage.component'
import MastheadPage from './components/MastheadPage/MastheadPage.component'
import Header from './components/Header/Header.component'
import Footer from './components/Footer/Footer.component'

import './components/Subscribe.styles.scss'

import './App.css'

function App() {
  return (
    <div className="App">
      <Header />
      <div className="main">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/masthead" element={<MastheadPage />} />
        </Routes>
      </div>
      <Footer />
    </div>
  )
}

export default App
