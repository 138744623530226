import { RalphCopy } from '../../data'
import './MastheadPage.styles.scss'

function MastheadPage() {
  const { issues } = RalphCopy

  return (
    <div className="issues-container">
      {issues.map((issue, index) => (
        <div id={issue.masthead.id} key={`masthead-issue-${index}`}>
          <div className="container masthead-title page-container">
            <div className="issue">
              <img
                src={require(`../../assets/imgs/${issue.home.issue.img}`)}
                alt={issue.home.issue.alt}
              />
            </div>
            <div className="title">
              <h2>{issue.home.title}</h2>
            </div>
          </div>

          <div className="container masthead-links page-container">
            <div className="links">
              <div className="contributor-container">
                <h3>{issue.masthead.features.title}</h3>

                <div className="contributors">
                  {issue.masthead.features.list.map((c, index) => (
                    <a
                      className="c-link"
                      key={`contributor-${index}`}
                      href={c.url}
                      target={c.target}
                      rel="noreferrer"
                    >
                      {c.name}
                    </a>
                  ))}
                </div>
              </div>
              <div className="contributor-container">
                <h3>{issue.masthead.contributors.title}</h3>

                <div className="contributors">
                  {issue.masthead.contributors.list.map((c, index) => (
                    <a
                      className="c-link"
                      key={`contributor-${index}`}
                      href={c.url}
                      target={c.target}
                      rel="noreferrer"
                    >
                      {c.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="promotions">
              {issue.masthead.promotions.map((p, index) => (
                <a
                  className="promotion-link"
                  href={p.url}
                  key={`promotion-${index}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="promotion">
                    <div className="issue">
                      <img src={require(`../../assets/imgs/${p.img}`)} alt={issue.home.issue.alt} />
                    </div>
                    <div className="star">
                      <div className="star-image">
                        <img src={require(`../../assets/imgs/star.svg`).default} alt="" />
                      </div>
                      <p>{p.title}</p>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default MastheadPage
