import { RalphCopy } from '../../data'
import './Footer.styles.scss'

function Footer() {
  const { footer } = RalphCopy

  return (
    <footer>
      <div className="top" dangerouslySetInnerHTML={{ __html: footer.contact }}></div>
      <div className="bottom" dangerouslySetInnerHTML={{ __html: footer.address }}></div>
    </footer>
  )
}

export default Footer
