import { Issue1 } from './issues/issue-1'
import { Issue2 } from './issues/Issue-2'

export const RalphCopy = {
  header: {
    title: 'POP CULTURE FOR THE FUN OF IT',
    tagline: 'tagline.png',
    buynow: {
      text: 'Buy now',
      url: 'https://www.mmslondon.co.uk/shop/p/ralphmagazineissu2'
    },
    instagram: {
      text: 'Instagram',
      url: 'https://instagram.com/ralph.mag'
    },
    subscribe: {
      text: 'Subscribe'
    }
  },
  subscribe: {},
  footer: {
    contact:
      'Editor: <a href="mailto:JoshJones@ralphmagazine.com">JoshJones@ralphmagazine.com</a> | Art Director: <a href="mailto:duffy@ralphmagazine.com">duffy@ralphmagazine.com</a> | Advertise in Ralph : <a href="mailto:chris@ralphmagazine.com">chris@ralphmagazine.com</a>',
    address:
      '© 2024 Ralph Creative Ltd, 2nd Floor, 27-33 Bethnal Green Road, London E1 6LA <a href="https://ralphandco.com" target="_blank">ralphandco.com</a>'
  },
  issues: [Issue2, Issue1]
}
